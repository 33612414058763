/* eslint-disable max-len */
import React from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import { map, sortBy, sum, sumBy } from 'lodash';
import DisplayField from '../../shared/components/DisplayField';
import { PrimaryButton } from '../../shared/components/GenericComponent';
import { WarningIcon } from '../../assets/img/imageExport/images';
import PopupModal from '../../shared/components/PopupModal';
import {
  displayToastMessage,
  formatNumberToIndianSystem,
  hasError,
  qtyValue,
  toRupees,
} from '../../shared/utils/Helper';
import { HSN_CODE } from '../../shared/utils/StringConstants';
import Theme from '../../theme/Theme';
import './CreditLimitModal.scss';
import { useSaleOrder } from '../../hooks/hooks';
import { ORDER_STATUS, TOAST_TYPES } from '../../shared/constants/Constants';
import { ErrorCode } from '../../shared/constants';
import getAggregatorPaymentDate, {
  getTotalAmountForAggregators,
} from '../../shared/utils/utilityFunctions';
import { onSaleOrderChanged } from '../../shared/events/AppEvents';

const CreditLimitModal = ({
  isShipmentCreation = false,
  shipmentData,
  creditLimitModal,
  soCreditLimitModal,
  setSoCreditLimitModal,
  setCreditLimitModal,
  saleOrderDetails,
  purchaseOrderDetails,
  handleUpdateRequisition,
  setIsApproved,
  setIsSoApproved,
  saleOrderData,
  hasPermissionShipment,
  hasPermissionSaleOrder,
  logisticLogs,
  order,
  availableCreditLimit,
  totalCreditLimit,
}) => {
  const closeModal = () => {
    isShipmentCreation ? setCreditLimitModal(false) : setSoCreditLimitModal(false);
  };

  const [
    { approving, aggRequesting, assignTruckFromKamorAppLoading },
    { doApproveOrder, doSendForApproval, doassignTruckFromKamorApp },
  ] = useSaleOrder();

  const approveShipment = async () => {
    const latetsLogisticLog = sortBy(logisticLogs, ['id'])?.reverse()?.[0];
    const payload = {
      sellerPaymentTermEventJson: latetsLogisticLog?.json,
      sellerPaymentTermDate:
        order?.sellerPaymentTerms?.length > 0 &&
        order?.sellerPaymentTerms?.[0]?.paymentDate !== null
          ? order?.sellerPaymentTerms?.[0]?.paymentDate
          : getAggregatorPaymentDate(order, ORDER_STATUS.APPROVED),
      isApprovedByUser: true,
    };
    const params = { shipmentId: order?.id };

    const res = await doApproveOrder(params, payload);
    if (res?.title === 'Margin dip') {
      closeModal();
      displayToastMessage(TOAST_TYPES?.ERROR, res?.message, false);
      return;
    }
    const error = hasError(res);
    if (error) {
      displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
    } else {
      displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.ORDER_APPROVED);
      onSaleOrderChanged(res);
    }
    closeModal();
  };
  const approveSaleOrder = () => {
    const payload = {
      status: 'APPROVED',
    };
    const toastTxt = 'Sale Order approved successfully.';
    handleUpdateRequisition(toastTxt, payload, true);
    setSoCreditLimitModal(false);
  };
  const gstAmount =
    ((saleOrderData?.mpSalesOrderItems?.[0]?.quantity *
      saleOrderData?.mpSalesOrderItems?.[0]?.taxPercentage) /
      100) *
    saleOrderData?.mpSalesOrderItems?.[0]?.price;
  const shipmentValue =
    sumBy(saleOrderDetails?.purchaseOrderDTO?.mpPurchaseOrderItems, 'quantity') *
    saleOrderDetails?.mpSalesOrderItems?.[0]?.price;
  const orderValue =
    saleOrderData?.mpSalesOrderItems?.[0]?.price * saleOrderData?.mpSalesOrderItems?.[0]?.quantity +
    gstAmount;

  const itemPriceAndQty = getTotalAmountForAggregators(
    saleOrderDetails?.mpShipmentItems,
    saleOrderDetails?.createdDate
  );

  const totalTaxableAmount = sum(itemPriceAndQty)?.toFixed(2);

  return (
    <PopupModal
      centered
      size="md"
      className="CreditLimitModal"
      show={isShipmentCreation ? creditLimitModal : soCreditLimitModal}
      onHide={closeModal}
      hasPermission={isShipmentCreation ? hasPermissionShipment : hasPermissionSaleOrder}
      title={
        <Row className="d-flex flex-row align-items-center ml-1">
          <div>
            <Image src={WarningIcon} />
          </div>
          <div
            className="ml-2"
            style={{ fontSize: '18px', fontFamily: 'font-bold', fontWeight: '500' }}>
            Credit Limit Exceeded
          </div>
        </Row>
      }
      buttons={[
        {
          name: isShipmentCreation ? 'Close' : 'Cancel',
          variant: 'primary',
          onClick: closeModal,
          className: 'cancel-button',
        },
        {
          name: isShipmentCreation ? 'Yes, Approve' : 'Approve Order',
          onClick: isShipmentCreation ? approveShipment : approveSaleOrder,
          variant: 'secondary',
          style: {
            borderRadius: '10px',
            border: `1px solid ${Theme.Colors.button_green}`,
            backgroundColor: Theme.Colors.button_green,
            color: Theme.Colors.base_white,
            fontFamily: 'font-bold',
          },
        },
      ]}>
      <div style={{ marginBottom: '45px' }}>
        <div className="m-2">
          <Row>
            <Col>
              {/* <DisplayField
                label={isShipmentCreation ? 'Total Shipment Value' : 'Total Order Value'}
                value={`₹ ${formatNumberToIndianSystem(
                  isShipmentCreation ? shipmentValue : orderValue || 0
                )}`}
              /> */}
              <Row>
                <span
                  className="ml-2 mt-2"
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#344054',
                    fontFamily: 'font-semibold',
                  }}>
                  {isShipmentCreation ? 'Total Shipment Value' : 'Total Order Value'}
                </span>
              </Row>
              <Row>
                <span
                  className="m-2"
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#344054',
                    fontFamily: 'font-semibold',
                  }}>
                  {isShipmentCreation
                    ? `₹ ${formatNumberToIndianSystem(totalTaxableAmount || '0.00')}`
                    : `₹ ${formatNumberToIndianSystem(orderValue || '0.00')}`}
                </span>
              </Row>
            </Col>
            <Col>
              {/* <DisplayField
                label="Available Credit limit"
                value={`₹ ${formatNumberToIndianSystem(
                  isShipmentCreation
                    ? saleOrderDetails?.recycler?.availableCredits
                    : saleOrderData?.recycler?.availableCredits || '-'
                )}`}
                valueStyle={{ color: 'red' }}
              /> */}
              <Row>
                <span
                  className="ml-2 mt-2"
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#344054',
                    fontFamily: 'font-semibold',
                  }}>
                  Available Credit limit
                </span>
              </Row>
              <Row>
                <span
                  className="m-2"
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#F04438',
                    fontFamily: 'font-semibold',
                  }}>
                  {isShipmentCreation
                    ? `₹ ${formatNumberToIndianSystem(availableCreditLimit || '0.00')}`
                    : `₹ ${formatNumberToIndianSystem(
                        saleOrderData?.buyerDetails?.mpRecyclerDetailsDTO?.availableCredits ||
                          '0.00'
                      )}`}
                </span>
              </Row>
              <Row>
                <span
                  className="ml-2"
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#667085',
                    fontFamily: 'font-semibold',
                  }}>
                  Total credit limit :
                </span>
                <span
                  className="ml-2"
                  style={{ fontSize: '12px', fontWeight: '600', color: '#344054' }}>
                  {isShipmentCreation
                    ? `₹ ${formatNumberToIndianSystem(totalCreditLimit || '0.00')}`
                    : `₹ ${formatNumberToIndianSystem(
                        saleOrderData?.buyerDetails?.mpRecyclerDetailsDTO?.creditLimit || '0.00'
                      )}`}
                </span>
              </Row>
            </Col>
          </Row>
        </div>
        <hr />

        {isShipmentCreation
          ? hasPermissionShipment && (
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#475467',
                  marginBottom: '50px',
                }}>
                Are you sure you want to approve?
              </span>
            )
          : hasPermissionSaleOrder && (
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#475467',
                  marginBottom: '50px',
                }}>
                Are you sure you want to approve?
              </span>
            )}
      </div>
    </PopupModal>
  );
};
export default CreditLimitModal;
