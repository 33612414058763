/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { indexOf, map } from 'lodash';
import React from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as DashboardIcon } from '../../assets/img/dashboard.svg';

import {
  BuyersDailyPricesIcon,
  CustomersIcon,
  GSTIcon,
  ItemsIcon,
  ListingIcon,
  LogisticIcon,
  OrdersIcon,
  PaymentsIcon,
  PricingListIcon,
  ReceivablesIcon,
  RequisitionIcon,
  SystemConfigIcon,
  OrderAllocationIcon,
  FinanceWorkAreaIcon,
} from '../../assets/img/imageExport/images';
import { useStakeholders } from '../../hooks';
import useResponsive from '../../hooks/useResponsive';
import { HasAuthority } from '../../shared/components';
import { useAuthority } from '../../shared/components/HasAuthority';
import { PARENT_NAV_BAR } from '../../shared/constants/Constants';

const AppSidebar = (props) => {
  const { collapsed, rtl, toggled, handleToggleSidebar, groupList } = props;
  const current = useLocation();
  const { currentAccount } = useStakeholders();

  const { isMobile } = useResponsive();
  const { hasAuth } = useAuthority();
  if (!current) return null;

  const iconComponentsMap = {
    Dashboard: <DashboardIcon />,
    items: <ItemsIcon />,
    sale_order: <RequisitionIcon />,
    listing: <ListingIcon />,
    orders: <OrdersIcon />,
    logistics: <LogisticIcon />,
    customers: <CustomersIcon />,
    payments: <PaymentsIcon />,
    receivables: <ReceivablesIcon />,
    gst: <GSTIcon />,
    daily_prices: <BuyersDailyPricesIcon />,
    system_config: <SystemConfigIcon />,
    order_allocation: <OrderAllocationIcon />,
    finance_workarea: <FinanceWorkAreaIcon />,
  };
  const childrenFeaturesNav = (obj) => {
    const urlNameParent = obj?.nameFormatted?.replaceAll(' ', '').toLowerCase();

    return {
      title: obj?.nameFormatted,
      icon: iconComponentsMap[obj?.name],
      url: `/${urlNameParent}`,
      children: map(obj?.modules, (innerObj) => {
        const urlNameChildren = innerObj?.nameFormatted.replaceAll(' ', '').toLowerCase();

        return {
          title: innerObj?.nameFormatted,
          url: `/${urlNameParent}/${urlNameChildren}`,
        };
      }),
    };
  };

  const parentFeaturesNav = (obj) => {
    const urlNameParent = obj?.nameFormatted.replaceAll(' ', '').toLowerCase();
    return {
      title: obj?.nameFormatted,
      icon: iconComponentsMap[obj?.name],
      url: `/${urlNameParent}`,
    };
  };

  const filteredGroupList = groupList?.filter((group) => group?.appCode === 'GXY004');
  const navMapper = map(filteredGroupList, (obj) => {
    return indexOf(PARENT_NAV_BAR, obj?.name) === -1
      ? parentFeaturesNav(obj)
      : childrenFeaturesNav(obj);
  });

  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint={isMobile ? 'xs' : 'md'}
      className="AppSidebar dark"
      onToggle={handleToggleSidebar}>
      <SidebarContent>
        <Menu>
          {currentAccount &&
            navMapper?.map((menu) => {
              return hasAuth(menu?.authorities) && menu?.children ? (
                <SubMenu
                  key={menu.url}
                  defaultOpen={current.pathname?.startsWith(menu?.url)}
                  className={classNames({
                    'submenu-active': current?.pathname?.startsWith(menu?.url),
                  })}
                  title={menu.title}
                  icon={menu.icon}>
                  {menu.children?.map((m, index) => (
                    <HasAuthority authorities={m?.authorities} key={`${m?.url}_${index}`}>
                      <MenuItem
                        className="child-menu-item"
                        key={m.url}
                        active={current.pathname === m.url}>
                        {m.title}
                        <Link
                          to={m.url}
                          onClick={isMobile ? () => handleToggleSidebar(false) : ''}
                        />
                      </MenuItem>
                    </HasAuthority>
                  ))}
                </SubMenu>
              ) : hasAuth(menu.authorities) ? (
                <MenuItem key={menu.url} icon={menu.icon} active={current.pathname === menu.url}>
                  {menu.title}
                  <Link to={menu.url} onClick={isMobile ? () => handleToggleSidebar(false) : ''} />
                </MenuItem>
              ) : (
                <div />
              );
            })}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default AppSidebar;
