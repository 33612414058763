import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { useAggregators, useRecyclers } from '../../../hooks/hooks';
import { useAsyncEffect } from '../../../hooks';

const AsyncAggregatorPaginationDropdown = (props) => {
  const { page, size, value, setValue, onInputChange, inputValue } = {
    ...props,
  };

  const [
    {
      value: { data: aggregatorData = [] },
    },
    { doFetchAggregator },
  ] = useAggregators();

  async function loadOptions(val) {
    const params = {
      page,
      size,
      stakeholderType: 'VENDOR',
      sort: 'id,desc',

      businessName: val,
    };
    const cancelToken = axios.CancelToken.source();
    const { status, data } = await doFetchAggregator({ data: params, source: cancelToken });
    const aggregators = [];
    data?.forEach((element) => {
      if (element?.businessName) {
        aggregators.push({
          label: `${element?.businessName} `,
          value: element?.id,
          aggregatorObj: element,
        });
      }
    });
    return {
      options: aggregators,
      hasMore: data?.length === 20,
      additional: {
        page: page + 1,
        size: 20,
      },
    };
  }

  return (
    <AsyncPaginate
      value={value}
      loadOptions={loadOptions}
      onChange={setValue}
      additional={{
        page: 0,
        size: 20,
      }}
      isClearable
      onInputChange={onInputChange}
      inputValue={inputValue}
      debounceTimeout={300}
    />
  );
};

export default AsyncAggregatorPaginationDropdown;
