import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { flatMap, uniqBy } from 'lodash';
import moment from 'moment';
import {
  CategoryDropdown,
  CityDropdown,
  ItemDropdown,
  KamListingsStatusDropdown,
  SaleOrderStatusDropdown,
} from './Dropdowns';
import MpDatePicker from './CustomDatePicker/MpDatePicker';
import DropdownSelect from './DropdownSelect';
import StateDropdownFilter from './Dropdowns/StateDropdownFilter';
import POOrderStatusDropdown from './Dropdowns/POOrderStatusDropDown';
import KamRequisitionStatusDropdown from './Dropdowns/KamRequisitionStatusDropdown';
import SellerStatusDropdown from './Dropdowns/SellerStatusDropdown';
import DocumentStatusDropdown from './Dropdowns/DocumentStatusDropdown';
import KamQuotesStatusDropdown from './Dropdowns/KamQuotesStatusDropdown';
import GSTStatusDropdown from './Dropdowns/GSTStatusDropdown';
import SaleOrderDocumentStatusDropDown from './Dropdowns/SaleOrderDocumentStatusDropDown';
import { toCapitalize, toLocalDate } from '../utils/Helper';
import TrackingStatusDropDown from './Dropdowns/TrackingStatusDropDown';
import TripStatusDropDown from './Dropdowns/TripStatusDropDown';
import MaterialTypeDropdown from './Dropdowns/MaterialTypeDropdown';
import { useCatalog, useStakeholders } from '../../hooks';
import {
  AUTHORITY,
  DEBIT_NOTE_ZOHO_STATUS,
  PAYMENT_TERM_EVENT,
  ROLE_MATERIAL,
} from '../constants/Constants';
import GstPaymentStatusDropdown from './Dropdowns/GstPaymentStatusDropdown';
import { useAggregators, useRecyclers, useTransporters } from '../../hooks/hooks';
import AsyncAggregatorPaginationDropdown from './Dropdowns/AsyncAggregatorPaginationDropdown';
import AsyncTransporterPaginationDropdown from './Dropdowns/AsyncTransporterPaginationDropdown';
import MaterialPaymentStatusDropdown from './Dropdowns/MaterialPaymentStatusDropdown';
import MultiSelectFilterDropdown from './Dropdowns/MultiSelectFilterDropdown';
import OsvStatus from './Dropdowns/OsvStatus';
import ComplianceStatus from './Dropdowns/ComplinaceStatus';
import LeadStatusDropdown from './Dropdowns/LeadsStatusDropdown';
import ZonesDropDown from './Dropdowns/ZonesDropDown';
import AllMaterialTypeDropdown from './Dropdowns/MainCategoryDropdown';
import ReportingListDropDown from './Dropdowns/ReportingListDropDown';
import RolesDropdown from './Dropdowns/RolesDropdown';
import ItemGroupedDropdown from './Dropdowns/ItemDropdown/ItemGroupedDropdown';
import AsyncRecyclerDropDown from './Dropdowns/AsyncRecyclerPaginationDropDown';

function SelectMaterialFilter({ filterValue, setFilter, preFilteredRows, id }) {
  const { items } = useCatalog();
  const [typeOptions, setTypeOptions] = useState([]);
  const { authorities } = useStakeholders().currentAccount;
  React.useEffect(() => {
    const options = flatMap(items)
      .map((item) => ({
        ...item.categoryDTO.materialType,
        itemId: item?.id,
      }))
      .reduce((acc, materialType) => {
        if (acc[materialType.id]) {
          acc[materialType.id].itemIds = [...acc[materialType.id].itemIds, materialType.itemId];
          return acc;
        }
        acc[materialType.id] = materialType;
        acc[materialType.id].itemIds = [materialType.itemId];
        return acc;
      }, {});
    const array = [];
    flatMap(options).map((mType) => {
      // eslint-disable-next-line dot-notation
      return ROLE_MATERIAL[mType?.name]?.map((e) => {
        if (authorities.includes(e)) {
          return array.push(mType);
        }
        return null;
      });
    });
    setTypeOptions(array);
  }, [items]);

  return (
    <MaterialTypeDropdown
      onChange={(i) => {
        setFilter(i?.id);
      }}
      typeOptions={typeOptions}
      value={typeOptions.length === 1 ? typeOptions[0]?.id : filterValue}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      classNamePrefix="react-select"
      menuPlacement="auto"
      maxMenuHeight={120}
      isDisabled={typeOptions.length === 1}
    />
  );
}

function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  // Render a multi-select box

  return (
    <ItemDropdown
      isAllItem
      value={filterValue || null}
      onChange={(i) => setFilter(i?.id)}
      isClearable
    />
  );
}

function SelectColumnFilterForAllItem({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  return (
    <ItemDropdown value={filterValue || null} onChange={(i) => setFilter(i?.id)} isClearable />
  );
}

function SelectStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, status } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  // Render a multi-select box
  return (
    <SaleOrderStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SelectKamListingStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <KamListingsStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SelectSuggestedPriceSourceFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Automated', id: 'Automated' },
    { name: 'Admin Portal', id: 'Admin Portal' },
    { name: 'None', id: 'None' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function SelectRequisitionStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <KamRequisitionStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SelectQuotesStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <KamQuotesStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function PoSelectStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <POOrderStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SelectColumnStateFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  // Render a multi-select box
  return (
    <StateDropdownFilter
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SelectCityCoulmnFilter({ column: { filterValue, setFilter, preFilteredRows } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  // Render a multi-select box

  return (
    <CityDropdown
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={Number(filterValue) || null}
      onChange={(i) => setFilter(i?.id)}
      isClearable
      isAllCitiesRequired
      // isMulti
    />
  );
}

function InputColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <div>
      <FormControl
        value={filterValue || ''}
        className="search-field"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder="Search"
      />
    </div>
  );
}

function DateRangeFilter({ column }) {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <MpDatePicker
        parentEl="#orderDate"
        id="orderDate"
        initialSettings={{
          autoUpdateInput: false,
          locale: {
            cancelLabel: 'Clear',
          },
          maxDate: new Date(),
          alwaysShowCalendars: true,
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
            'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
          },
        }}
        inputClass="search-field"
        startDate={filterValue ? filterValue[0] : ''}
        endDate={filterValue ? filterValue[1] : ''}
        placeholder="Search"
        onApply={({ startDate, endDate }, $event) => {
          setFilter([startDate, endDate]);
        }}
        onCancel={(event, picker) => {
          picker.element.val('');
          setFilter(undefined);
        }}
      />
    </div>
  );
}

function InputColumnDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Yes', id: 'true' },
    { name: 'No', id: 'false' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select..."
      />
    </div>
  );
}

function DynamicInputColumnFilter({
  column: { filterValue, preFilteredRows, setFilter, filterParam, id, operation },
}) {
  return (
    <div>
      <FormControl
        value={filterValue?.value || ''}
        className="search-field"
        onChange={(e) => {
          setFilter({ id, value: e.target.value, operation, filterParam });
        }}
        placeholder="Search"
      />
    </div>
  );
}

function DynamicDateRangeFilter({
  column: { filterValue, setFilter, id, operation, filterParam },
}) {
  return (
    <div>
      <MpDatePicker
        parentEl="#orderDate"
        id="orderDate"
        initialSettings={{
          autoUpdateInput: false,
          locale: {
            cancelLabel: 'Clear',
          },
          maxDate: new Date(),
          alwaysShowCalendars: true,
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate(),
            ],
            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
            'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate(),
            ],
          },
        }}
        inputClass="search-field"
        startDate={filterValue ? filterValue?.value?.[0] : ''}
        endDate={filterValue ? filterValue?.value?.[1] : ''}
        placeholder="Search"
        onApply={({ startDate, endDate }, $event) => {
          setFilter({ id, value: [startDate, endDate], operation, filterParam });
        }}
        onCancel={(event, picker) => {
          picker.element.val('');
          setFilter({ id, value: ['', ''], operation, filterParam });
        }}
      />
    </div>
  );
}

function DynamicColumnDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter, filterParam, operation, id },
  options: opt,
}) {
  return (
    <div style={{ color: 'black' }}>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter({ value: e?.id, operation, filterParam });
        }}
        isMulti={false}
        value={filterValue?.value}
        options={JSON.parse(opt)}
        placeholder="Select."
        style={{ color: 'red' }}
      />
    </div>
  );
}

function MultiDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter, filterParam, operation, id },
  options: opt,
}) {
  let mapper;
  return (
    <div style={{ color: 'black' }}>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          mapper = e?.map((i) => i?.id);
          setFilter({ value: Object.values(mapper)?.join(','), operation, filterParam });
        }}
        isMulti
        value={mapper}
        options={JSON.parse(opt)}
        placeholder="Select."
        style={{ color: 'red' }}
      />
    </div>
  );
}

function DynamicColumnMultiDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter, filterParam, operation, id },
  options: opt,
}) {
  return (
    <MultiSelectFilterDropdown
      setFilter={setFilter}
      filterValue={filterValue}
      filterParam={filterParam}
      operation={operation}
      id={id}
      options={JSON.parse(opt)}
    />
  );
}

function NoteTypeColumnDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'C', id: 'C' },
    { name: 'D', id: 'D' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function StatusColumnDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Active', id: '1' },
    { name: 'Inactive', id: '0' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function EnabledDisabledColumnDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const opt = [
    { name: 'Enabled', id: 'true' },
    { name: 'Disabled', id: 'false' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function ItemTypeColumnDropdownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'M2', id: 'M2' },
    { name: 'M3', id: 'M3' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function CategoryDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <AllMaterialTypeDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
      placeholder="Select."
      isAllMaterialsRequired
    />
  );
}

function ZonesDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <ZonesDropDown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
      placeholder="Select."
    />
  );
}

function RolesDropdownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <RolesDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
      placeholder="Select."
      belongsToAddUser={false}
    />
  );
}

function ReportingListDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <ReportingListDropDown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
      placeholder="Select."
    />
  );
}

function RolesColumnDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const roles = [
    { name: 'Admin', id: 'ROLE_ADMIN' },
    { name: 'Kam', id: 'ROLE_KEY_ACCOUNT_MANAGER' },
    { name: 'Accounts', id: 'ROLE_MARKETPLACE_ACCOUNTS' },
    { name: 'Business Head', id: 'ROLE_MARKETPLACE_BUSINESS' },
    { name: 'Specific Point of contact (SPOC)', id: AUTHORITY.ROLE_SPOC },
    { name: 'Fulfilment User', id: 'ROLE_FULFILMENT_USER' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        value={filterValue}
        options={roles}
        placeholder="Select."
      />
    </div>
  );
}

function GSTSelectStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <GSTStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SellerStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, status } }) {
  return (
    <SellerStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function OsvVerificationStatusFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <OsvStatus
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function LeadStatusFilter({ column: { filterValue, setFilter, preFilteredRows, status } }) {
  return (
    <LeadStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function ComplianceVerificationStatusFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <ComplianceStatus
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SelectCategoriesColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  return (
    <CategoryDropdown
      value={filterValue || null}
      onChange={(i) => setFilter(i?.id)}
      isClearable
      aLLCategoriesRequired
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
    />
  );
}

function SelectMaterialColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const { items, materialTypes } = useCatalog();
  const [typeOptions, setTypeOptions] = useState([]);
  let options = materialTypes;
  options = uniqBy(options, (e) => e?.id);
  useEffect(() => {
    setTypeOptions([...options]);
  }, []);
  return (
    <MaterialTypeDropdown
      value={filterValue || null}
      onChange={(i) => setFilter(i?.id)}
      isClearable
      allMaterialsRequired
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      typeOptions={typeOptions}
    />
  );
}

function CategoriesBasedOnMaterialTypeColumnFilter({ column: { filterValue, setFilter }, mId }) {
  return (
    <CategoryDropdown
      value={filterValue || null}
      onChange={(i) => setFilter(i?.id)}
      isClearable
      aLLCategoriesRequired={false}
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      MaterialTypeId={mId}
    />
  );
}

function DocumentReconcileStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  return (
    <DocumentStatusDropdown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function SaleOrderDocumentStatus({ column: { filterValue, setFilter, preFilteredRows, status } }) {
  return (
    <SaleOrderDocumentStatusDropDown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function PaymentsRecomendColumnDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const opt = [
    { name: 'Pay', id: 'PAY' },
    { name: 'Hold', id: 'HOLD' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function PaymentsStatusColumnDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const opt = [
    { name: 'Paid', id: 'Paid' },
    { name: 'Payable', id: 'Payable' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function GSTR3BStatusColumnDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Yes', id: 'Y' },
    { name: 'No', id: 'N' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function DateFilter({ column: { filterValue, setFilter } }) {
  const [startDate, setStartDate] = useState(toLocalDate(filterValue, 'YYYY-MM-DD'));
  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setFilter(toLocalDate(date, 'YYYY-MM-DD'));
          setStartDate(date);
        }}
        showYearDropdown
        yearDropdownItemNumber={15}
        scrollableYearDropdown
      />
    </div>
  );
}

function CampaignStatusDropDownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Active', id: 'ACTIVE' },
    { name: 'Draft', id: 'DRAFT' },
    { name: 'Inactive', id: 'INACTIVE' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function CampaignSellerStatusDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const opt = [
    // { name: 'View', id: 'VIEW' },
    { name: 'Used', id: 'USED' },
    { name: 'Unused', id: 'UNUSED' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function TrackingStatusColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, status },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  // Render a multi-select box
  return (
    <TrackingStatusDropDown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function TripStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, status } }) {
  return (
    <TripStatusDropDown
      value={filterValue || null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
      isClearable
    />
  );
}

function MultiSelectItemFilter({ column: { filterValue, setFilter } }) {
  return <ItemGroupedDropdown setFilter={setFilter} value={filterValue || null} />;
}

function MultiSelectItemFilterInRecyclers({ column: { filterValue, setFilter } }) {
  return <ItemGroupedDropdown setFilter={setFilter} value={filterValue || null} isRecycler />;
}

function RequisitionSourceTypeColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const opt = [
    { name: 'App', id: 'APP' },
    { name: 'Portal', id: 'PORTAL' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function MaterialPaymentStatusColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const [isExternalValue, setIsExternalValue] = useState(true);
  return (
    <div>
      <MaterialPaymentStatusDropdown
        value={filterValue ?? ''}
        onChange={(i) => {
          setIsExternalValue(false);
          setFilter(i?.id);
        }}
        isClearable
      />
    </div>
  );
}

function GSTPaymentStatusColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <div>
      <GstPaymentStatusDropdown
        value={filterValue || null}
        onChange={(i) => {
          setFilter(i?.id);
        }}
        isClearable
      />
    </div>
  );
}
function AggregatorAsyncColumnFilter({ column: { filterValue, setFilter, preFilteredRows } }) {
  const [aggInputVal, setAggInputVal] = useState();
  const [{ getValue }, { doGetAggregatorById }] = useAggregators();
  const aggInputChange = (inputVal) => {
    setAggInputVal(inputVal);
  };

  useEffect(() => {
    if (filterValue) {
      doGetAggregatorById(filterValue);
    }
  }, [filterValue]);

  return (
    <AsyncAggregatorPaginationDropdown
      value={filterValue && getValue ? { ...getValue, label: getValue.businessName } : null}
      page={0}
      setValue={(e) => {
        setFilter(e?.value);
      }}
      size={20}
      onInputChange={(e) => aggInputChange(e)}
      inputValue={aggInputVal}
      isClearable
    />
  );
}

function TransporterAsyncColumnFilter({ column: { filterValue, setFilter, preFilteredRows } }) {
  const [transInputVal, setTransInputVal] = useState();
  const [{ getValue }, { doFetchTransporterById }] = useTransporters();
  const transInputChange = (inputVal) => {
    setTransInputVal(inputVal);
  };

  useEffect(() => {
    if (filterValue) {
      doFetchTransporterById(filterValue);
    }
  }, [filterValue]);

  return (
    <AsyncTransporterPaginationDropdown
      value={filterValue && getValue ? { ...getValue, label: getValue.name } : null}
      page={0}
      setValue={(e) => {
        setFilter(e?.value);
      }}
      size={20}
      onInputChange={(e) => transInputChange(e)}
      inputValue={transInputVal}
      isClearable
    />
  );
}

function MaterialTypeFilter({ column: { filterValue, setFilter, preFilteredRows } }) {
  const { materialTypes, materialTypesList } = useCatalog();
  const selectedMaterialType = materialTypesList?.[filterValue];
  const options = materialTypes?.map((e) => ({ id: Number(e?.id), label: e?.name }));
  const data = { id: Number(selectedMaterialType?.id), label: selectedMaterialType?.name };
  return (
    <DropdownSelect
      options={options}
      getOptionLabel={(option) => `${option?.label}`}
      getOptionValue={(option) => `${option?.id}`}
      classNamePrefix="react-select"
      menuPlacement="auto"
      maxMenuHeight={220}
      isMulti={false}
      value={filterValue ? data : null}
      onChange={(i) => {
        setFilter(i?.id);
      }}
    />
  );
}

function ZohoStatusColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Created', id: 'CREATED' },
    { name: 'Not Created', id: 'NOT_CREATED' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue ?? ''}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function SpocUsersFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const { kamList } = useStakeholders();
  const spocList = kamList?.filter((user) => {
    const userRoles = [
      'ROLE_BUSINESS_MANAGER',
      'ROLE_BUSINESS_EXECUTIVE',
      'ROLE_FIELD_EXECUTIVE',
      'ROLE_E_WASTE_BUSINESS',
    ];
    const authorityList = user?.authorities?.filter((auth) => userRoles.includes(auth));
    return authorityList && authorityList.length > 0;
  });
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => {
          return `${option?.firstName}`;
        }}
        getOptionValue={(option) => {
          return `${option?.userId}`;
        }}
        onChange={(e) => {
          setFilter(e?.userId);
        }}
        isMulti={false}
        value={filterValue}
        options={spocList}
        placeholder="Select."
        valueKey="userId"
      />
    </div>
  );
}

function AccountsExucutiveUserFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const { kamList, kamUsers } = useStakeholders();
  const userRoles = ['ROLE_MP_INVOICE_PROCESSOR'];

  const users = kamList?.filter((user) =>
    user?.authorities?.some((auth) => userRoles.includes(auth))
  );

  const userNames = users?.map((item) => {
    const foundUser = flatMap(kamUsers)?.find((user) => user?.userId === item?.userId);
    return foundUser;
  });

  const selectedUser = userNames?.find((user) => user?.userId === filterValue);

  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => {
          return option?.firstName;
        }}
        getOptionValue={(option) => {
          return option?.userId;
        }}
        onChange={(selectedOption) => {
          setFilter(selectedOption?.userId || undefined);
        }}
        isMulti={false}
        value={selectedUser}
        options={userNames}
        placeholder="Select."
        valueKey="userId"
      />
    </div>
  );
}

function TruckPlacementDropdownFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Recykal ', id: 'false' },
    { name: 'Seller', id: 'true' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

function GSTFilingSatusColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const opt = [
    { name: 'Filed', id: 'Filed' },
    { name: 'Not Filed', id: 'Not_Filed' },
  ];
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={opt}
        placeholder="Select."
      />
    </div>
  );
}

/** @description custom date range filter */
function CustomDateRangeFilter({ column: { filterValue, setFilter } }) {
  return (
    <div>
      <MpDatePicker
        parentEl="#nextRequisitionDate"
        id="nextRequisitionDate"
        initialSettings={{
          autoUpdateInput: false,
          locale: {
            cancelLabel: 'Clear',
          },
          minDate: new Date(),
          alwaysShowCalendars: true,
          // showCustomRangeLabel: false,
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Tomorrow: [moment().add(1, 'days').toDate(), moment().add(1, 'days').toDate()],
            'Next 3 Days': [moment().add(1, 'days').toDate(), moment().add(3, 'days').toDate()],
            'Next 7 Days': [moment().add(1, 'days').toDate(), moment().add(7, 'days').toDate()],
          },
        }}
        inputClass="search-field"
        startDate={filterValue ? filterValue[0] : ''}
        endDate={filterValue ? filterValue[1] : ''}
        placeholder="Search"
        onApply={({ startDate, endDate }) => {
          setFilter([startDate, endDate]);
        }}
        onCancel={(event, picker) => {
          picker.element.val('');
          setFilter(undefined);
        }}
      />
    </div>
  );
}

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  const count = preFilteredRows?.length;

  return (
    <FormControl
      value={filterValue || ''}
      className="search-field"
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Search"
    />
  );
};

const DropdownFilter = ({ column: { filterValue, setFilter, id, filterOptions } }) => {
  return (
    <DropdownSelect
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e?.value || undefined); // Set undefined to remove the filter entirely
      }}
      options={filterOptions}
      getOptionLabel={(e) => e.value}
      getOptionValue={(e) => e.value}
      valueKey="value"
    />
  );
};

function PaymentTermFilters({ column: { filterValue, preFilteredRows, setFilter } }) {
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.event}`}
        onChange={(e) => {
          setFilter(e?.event);
        }}
        isMulti={false}
        value={filterValue || ''}
        options={PAYMENT_TERM_EVENT}
        placeholder="Select."
        valueKey="event"
      />
    </div>
  );
}

function UpdatesColumnDropDownFilter({
  column: { filterValue, preFilteredRows, setFilter, inlineOptionList },
}) {
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        value={filterValue}
        options={inlineOptionList ?? []}
        placeholder="Select"
      />
    </div>
  );
}

function BuyerAsyncColumnFilter({ column: { filterValue, setFilter, preFilteredRows } }) {
  const [buyerInputVal, setBuyerInputVal] = useState();
  const [{ getValue }, { doGetRecyclerById }] = useRecyclers();

  const buyerInputChange = (inputVal) => {
    setBuyerInputVal(inputVal);
  };

  useEffect(async () => {
    if (filterValue) {
      await doGetRecyclerById(filterValue);
    }
  }, [filterValue]);

  return (
    <AsyncRecyclerDropDown
      value={filterValue && getValue ? { ...getValue, label: getValue.businessName } : null}
      page={0}
      setValue={(e) => {
        setFilter(e?.recyclerObj?.mpRecyclerDetailsDTO?.id);
      }}
      size={20}
      onInputChange={(e) => buyerInputChange(e)}
      inputValue={buyerInputVal}
      isClearable
    />
  );
}

function SelectDebitNoteStatusColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <div>
      <DropdownSelect
        isClearable
        getOptionLabel={(option) => `${option?.name}`}
        getOptionValue={(option) => `${option?.id}`}
        onChange={(e) => {
          setFilter(e?.id);
        }}
        isMulti={false}
        options={DEBIT_NOTE_ZOHO_STATUS}
        value={filterValue}
        placeholder="Select..."
      />
    </div>
  );
}

export {
  SelectColumnFilter,
  SelectMaterialFilter,
  SelectColumnFilterForAllItem,
  InputColumnFilter,
  SelectStatusColumnFilter,
  InputColumnDropDownFilter,
  SelectCityCoulmnFilter,
  DateRangeFilter,
  SelectColumnStateFilter,
  RolesColumnDropDownFilter,
  PoSelectStatusColumnFilter,
  SelectKamListingStatusColumnFilter,
  SelectRequisitionStatusColumnFilter,
  SelectQuotesStatusColumnFilter,
  GSTSelectStatusColumnFilter,
  SellerStatusColumnFilter,
  SelectCategoriesColumnFilter,
  SelectMaterialColumnFilter,
  NoteTypeColumnDropDownFilter,
  DocumentReconcileStatusColumnFilter,
  SaleOrderDocumentStatus,
  SelectSuggestedPriceSourceFilter,
  PaymentsRecomendColumnDropDownFilter,
  PaymentsStatusColumnDropDownFilter,
  GSTR3BStatusColumnDropDownFilter,
  DateFilter,
  CampaignStatusDropDownFilter,
  CampaignSellerStatusDropDownFilter,
  TrackingStatusColumnFilter,
  TripStatusColumnFilter,
  RequisitionSourceTypeColumnFilter,
  MultiSelectItemFilter,
  MultiSelectItemFilterInRecyclers,
  AggregatorAsyncColumnFilter,
  MaterialPaymentStatusColumnFilter,
  GSTPaymentStatusColumnFilter,
  MaterialTypeFilter,
  TransporterAsyncColumnFilter,
  ZohoStatusColumnFilter,
  DynamicColumnDropDownFilter,
  DynamicDateRangeFilter,
  DynamicInputColumnFilter,
  DynamicColumnMultiDropDownFilter,
  OsvVerificationStatusFilter,
  ComplianceVerificationStatusFilter,
  LeadStatusFilter,
  SpocUsersFilter,
  AccountsExucutiveUserFilter,
  TruckPlacementDropdownFilter,
  GSTFilingSatusColumnFilter,
  CustomDateRangeFilter,
  CategoryDropDownFilter,
  ZonesDropDownFilter,
  ReportingListDropDownFilter,
  RolesDropdownFilter,
  StatusColumnDropDownFilter,
  EnabledDisabledColumnDropDownFilter,
  CategoriesBasedOnMaterialTypeColumnFilter,
  ItemTypeColumnDropdownFilter,
  DefaultColumnFilter,
  DropdownFilter,
  UpdatesColumnDropDownFilter,
  PaymentTermFilters,
  BuyerAsyncColumnFilter,
  SelectDebitNoteStatusColumnFilter,
};
